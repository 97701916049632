// import React from 'react'
import react, { useContext, useEffect, useState} from "react";

import { Container, Row, Col } from 'react-bootstrap'
import { AuthContext } from '../../../auth'
import Logo from '../../../assets/img/sports-logo-.png'
import consorcio1 from "../../../assets/consorcio_img/consorcio1.png"
import consorcio2 from "../../../assets/consorcio_img/consorcio2.png"
import consorcio3 from "../../../assets/consorcio_img/consorcio3.png"
import Layout from '../structures/layout'
import 'bootstrap-icons/font/bootstrap-icons.css';
import moment from "moment";
import { redApi as api } from "../../../config/Axios";
import CanvasBarChart from "./BarChart";
// import BarChart from "./BarChart";
import Swal from "sweetalert2";

export default function Home() {
  const { userName, userLevel, userId } = useContext(AuthContext);
  const [consort, setConsort] = useState({
    consorcio: userName === "admin" || !userName ? "All" : userName,
    userId
  });
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [data, setData] = useState({});

  const handleChange = (event) => {
    setConsort(prevState => ({
      ...prevState,
      consorcio: event.target.value
  }));
  };

  const getAllInfoTickets = async () => {
    try {
      Swal.fire({
        width: "fit-content",
        didOpen: () => {
          Swal.showLoading()
        }
      })
      const { data } = await api.get(`/ticketsInfo?info=true&consorcio=${consort.consorcio}&userId=${consort.userId}&date=${date}`);
      setData(data)
    } catch (error) {
      console.log("error", error);
    } finally {
      Swal.close()
    }
  };

  useEffect(()=>{
    getAllInfoTickets();
  },[consort, date])
 
  let ganacias=0;
  let perdidas=0;
  let balanceBlock;
  // let datos;
  if(data.resultArray){
    // datos = data.resultArray;
    balanceBlock = data.balanceBlock;

    const result = data.resultArray.reduce((acc, ele)=> {
      acc.ganacias += ele.amountOfTicketsLosser;
      acc.perdidas += ele.amountOfTicketsWin;
      return acc;
    },{ganacias:0, perdidas:0});
  
    ganacias = result.ganacias;
    perdidas = result.perdidas;
  }
  const restringir = async () => {
    try {
      const resp = await api.put('/users/balanceBlock', {consorcio: consort.consorcio})
      getAllInfoTickets() 
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Ocurrio Un Error",
        showConfirmButton: false,
        timer: 2000
    })
    }
  }
  const quitarRestriccion = async () => {
    try {
      const resp = await api.put('/users/balanceUnblock', {consorcio: consort.consorcio})
      getAllInfoTickets()
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Ocurrio Un Error",
        showConfirmButton: false,
        timer: 2000
    })
    }
  }
  const labels = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  return (
    <Layout>
        <Container className="p-4">
            <Row  className="d-flex align-items-center">
                <Col xs={12} className="d-flex justify-content-center logo-inicio">
                   <h1>Admin Dashboard</h1>
                  <img src={userName === "boston" ? consorcio1 : userName === "caracas" ? consorcio2 : "/sports_favicon.png"} alt="Sports"  style={{width: "4%", marginBottom: "60px"}}/>

                </Col>
               
            </Row>
            <Row  className="d-flex align-items-center" style={{border:'1px solid gray', borderRadius:'5px', backgroundColor:'white', padding:'10px', marginBottom:'10px'}}>
                <Col xs={3} className="d-flex justify-content-center logo-inicio">
                   <label htmlFor='date' style={{margin:'auto'}}>Fecha</label>
                   <input type="date" id="date" name='date' value={date} style={{marginLeft:'5px'}} onChange={(e) => setDate(e.target.value)} />
                </Col>
                <Col xs={3} className="d-flex justify-content-center logo-inicio">
                   <label htmlFor='consorcio'>Consorcio</label>
                   <select id="consorcio" name='consorcio' value={consort.consorcio} onChange={handleChange} disabled={userName == 'boston' || userName == 'caracas' || userName == 'new york'}>
                     <option value="All">All</option>
                     <option value="new york">New York</option>
                     <option value="boston">Boston</option>
                     <option value="caracas">Caracas</option>
                   </select>
                </Col>
                {
                  userName === "admin" || !userName ?
                    <Col xs={6} className="d-flex justify-content-end">
                      <button style={{width:'inherit', backgroundColor: consort.consorcio === "All" ? 'gray' : 'red', color:'white'}} disabled={consort.consorcio === "All"} onClick={balanceBlock ? quitarRestriccion : restringir}>{ balanceBlock === false ? "Restringir Consorcio" : consort.consorcio === "All" ? "Seleccione Un Consorcio" : "Quitar Restriccion"}</button>
                    </Col>
                    : null
                }
            </Row>
            <Row  className="d-flex align-items-center" style={{border:'1px solid gray', borderRadius:'5px', backgroundColor:'white', padding:'inherit', marginBottom:'10px'}}>
                <Col xs={3}  className="d-flex flex-column align-items-center logo-inicio" style={{border:'1px solid gray', borderRadius:'5px', backgroundColor:'white' , padding:'inherit'}}>
                   <i className="bi bi-ticket" style={{fontSize:'35px', color:'red'}}></i>
                   <p style={{fontWeight:'bold', fontSize:'30px'}}>{data.ticketsVendidos}</p>
                   <p>Tickets Vendidos</p>
                   <p style={{fontWeight:'bolder'}}>Esta semana : {data.ticketsVendidos}</p>
                </Col>
                <Col xs={3} className="d-flex flex-column align-items-center logo-inicio" style={{border:'1px solid gray', borderRadius:'5px', backgroundColor:'white', padding:'inherit'}}>
                   <i className="bi bi-cash-stack" style={{fontSize:'35px', color:'green'}}></i>
                   <p style={{fontWeight:'bold', fontSize:'30px'}}>{data.ingTickets}</p>
                   <p>Ingreso en Tickets</p>
                   <p style={{fontWeight:'bolder'}}>Esta semana : {data.ingTickets}</p>
                </Col>
                <Col xs={3}  className="d-flex flex-column align-items-center logo-inicio" style={{border:'1px solid gray', borderRadius:'5px', backgroundColor:'white', padding:'inherit'}}>
                  <i className="bi bi-dice-3" style={{fontSize:'35px', color:'orange'}}></i>
                  <p style={{fontWeight:'bold', fontSize:'30px'}}>{data.totalBets}</p>
                   <p>Jugada de POS</p>
                   <p style={{fontWeight:'bolder'}}>Esta semana : {data.totalBets}</p>
                </Col>
                <Col xs={3}  className="d-flex flex-column align-items-center logo-inicio" style={{border:'1px solid gray', borderRadius:'5px', backgroundColor:'white', padding:'inherit'}}>
                  <i className="bi bi-dice-4" style={{fontSize:'35px', color:'blue'}}></i>  
                  <p style={{fontWeight:'bold', fontSize:'30px'}}>0</p>
                   <p>Jugadas Web Users</p>
                   <p style={{fontWeight:'bolder'}}>Esta semana : 0</p>
                </Col>
            </Row>
            <Row  className="d-flex align-items-center" style={{border:'1px solid gray', borderRadius:'5px', backgroundColor:'white', padding:'10px', marginBottom:'10px'}}>
                <Col xs={12} className="d-flex justify-content-center logo-inicio">
                <h5 style={{fontWeight:'bolder'}}>Estadisticas (cifras en USD)</h5>
                  
                </Col>
                <Col xs={12} className="d-flex justify-content-center logo-inicio">
                
                 <CanvasBarChart data={data.resultArray} labels={labels}/> 
                 {/* <BarChart datos={datos} labels={labels}/>*/}
                </Col>
                <Col xs={12} className="d-flex justify-content-center logo-inicio">
                  <p style={{backgroundColor:'red', width:'30px', height:'10px', marginTop:'10px', borderRadius:'10px'}}></p>
                  <span style={{margin:'5px'}}>Premios</span>
                  <p style={{backgroundColor:'green', width:'30px', height:'10px',marginTop:'10px',borderRadius:'10px'}}></p>
                  <span style={{margin:'5px'}}>Ventas</span>
                </Col> 
          
            </Row>
            <Row>
            <Col xs={3}  style={{border:'1px solid gray', borderRadius:'5px', backgroundColor:'white', display: 'flex', flexDirection: 'column',justifyContent: 'center',alignItems: 'center', height:'200px'  }}>
           
            <div >
              <i className="fas fa-chart-line" style={{ fontSize: '24px', color: 'green' }}></i>
              <i className="fas fa-arrow-up" style={{ fontSize: '24px', color: 'green' }}></i>
            </div>
            <div>
             <p style={{fontSize:'25px', fontWeight:'bold'}}>Ganacias</p>
            </div>
            <div>
              <h1 style={{fontWeight:'bold'}}>$ {ganacias}</h1>
            </div>
            <div>
             <p style={{fontWeight:'bold'}}>Esta Semana</p>
            </div>
            </Col>
            <Col xs={3}  style={{border:'1px solid gray', borderRadius:'5px', backgroundColor:'white', display: 'flex', flexDirection: 'column',justifyContent: 'center',alignItems: 'center'  }}>
                 
            <div >
              <i className="fas fa-chart-line" style={{fontSize:'24px', color:'red'}}></i>

              <i className="fas fa-arrow-down" style={{fontSize:'24px', color:'red'}}></i>
            </div>
            <div>
             <p style={{fontSize:'25px', fontWeight:'bold'}}>Perdidas</p>
            </div>
            <div>
              <h1 style={{fontWeight:'bold'}}>$ {perdidas}</h1>
            </div>
            <div>
             <p style={{fontWeight:'bold'}}>Esta Semana</p>
            </div>

            </Col>
            </Row>
        </Container>
    </Layout>
  )
}
