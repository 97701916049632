import React, { useCallback, useContext, useEffect, useState } from "react";
import Footer from "../../navigation/theme3/Legacy_footer";
import Header from "../../navigation/theme3/Legacy_header";
import LeftContainer from "../../navigation/theme3/Legacy_left";
import RightContainer from "../../navigation/theme3/Legacy_right";
import {
  BgColorsOutlined,
  CommentOutlined,
  FullscreenOutlined,
  LockOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  QuestionCircleOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import "./theme3_layout.scss";
import Legacy_rightMobile from "../../navigation/theme3/Legacy_rightMobile";
import TeamsMobile from "../../navigation/theme3/Legacy_teamsMobile";
import MobileKeyboard from "../../navigation/theme3/MobileKeyboard";
import Legacy_leftMobile from "../../navigation/theme3/Legacy_leftMobile";
import { Modal } from "antd";
import { redApi } from "../../../../config/Axios";
import Swal from "sweetalert2";
import { AuthContext } from "../../../../auth";
import { GlobalContext } from "../../../../globalContext";

const Layout = ({
  children,
  bets,
  setBets,
  betToAdd,
  setBetToAdd,
  gamesForUser,
  isLoading,
  totalToWin,
  setTotalToWin,
  totalToWinIF,
  setTotalToWinIF,
  handleBetAddToTable,
  mobile,
  setMobile,
  handleBetPost,
  handleChangeCheckBoxs,
  allowPrint,
}) => {
  const { userTheme: themeofuser } = useContext(AuthContext);
  const { sameTeamPlay, handleSameTeamPlay } = useContext(GlobalContext);

  const handleChangeBetToAdd = (e) => {
    let { name, value } = e.target;
  
    // Si el nombre es "TeamCode" y la longitud del valor es mayor a 4, corta el valor
    if (name === "TeamCode" && value.length > 4) {
      value = value.slice(0, 4); // Limita a los primeros 4 caracteres
    }

    if (name === "betTypeSelect") {
      handleSameTeamPlay(false);
    }

    if (name === "TeamCode" && betToAdd.TeamCode !== value) {
      handleSameTeamPlay(false);
    }
  
    setBetToAdd({
      ...betToAdd,
      [name]: name === "Quantity" ? parseInt(value) : value.toUpperCase(),
    });
  
    console.log("sameTeamPlay", sameTeamPlay);
  
    // Lógica para el enfoque dependiendo de `sameTeamPlay` o el valor de `TeamCode`
    if (sameTeamPlay) {
      document.querySelector("#TeamCode").focus();
    } else if (name === "TeamCode" && value.length === 4) {
      if (mobile) {
        setInputFocus("betTypeSelect");
        return;
      }
      console.log("4");
      document.querySelector("#betTypeSelect").focus();
    }
  
    // Lógica para actualizar `bets` cuando se cambia la cantidad
    if (name === "Quantity" && parseInt(value) > 0) {
      setBets({
        ...bets,
        ammount: parseInt(value),
      });
    }
  };
  

  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpenMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [navmenuSelected, setNavmenuSelected] = useState("VENDER");

  const handleNavmenuSelected = (e) => {
    setNavmenuSelected(e.target.value);
  };

  const [jugadas, setJugadas] = useState(0);
  useEffect(() => {
    // console.log('bets', bets)
    if (bets.bets) {
      setJugadas(bets.bets.length > 0 ? bets.bets.length : 0);
    }
  }, [bets.bets]);
  const [keyboard, setKeyboard] = useState(false);

  const handleKeyboard = () => {
    setKeyboard(!keyboard);
  };
  useEffect(() => {
    if (onlyNumbers) return;
    let keyboardmobile = document.querySelector("#keyboardmobile");
    if (keyboardmobile)
      navmenuSelected === "VENDER" || navmenuSelected === "BETTYPE"
        ? keyboardmobile.classList.toggle("show")
        : keyboardmobile.classList.remove("show");
    let TeamCode = document.querySelector("#TeamCode");
    if (TeamCode) {
      TeamCode.addEventListener("focus", () => {
        setNavmenuSelected(keyboard ? "TEAMCODE" : "VENDER");
        setInputFocus("TeamCode");
      });

      return () => {
        TeamCode.removeEventListener("focus", () => {});
      };
    }
  }, []);
  function getToWin(bets, amount) {
    let result = 0;
    for (let i = 0; i < bets.length; i++) {
      if (
        bets[i].price.toString().includes("+") &&
        bets[i].price.toString().includes("-")
      ) {
      }
      const multiplier =
        bets[i].price >= 0
          ? bets[i].price / 100
          : 1 / ((bets[i].price * -1) / 100);
      result = (result + amount) * multiplier + result;
    }
    result = Math.round(result);
    return result;
  }
  useEffect(() => {
    let betTypeSelect = document.querySelector("#betTypeSelect");
    if (betTypeSelect) {
      betTypeSelect.addEventListener("focus", () => {
        setNavmenuSelected("BETTYPE");
        setInputFocus("betTypeSelect");
      });

      return () => {
        betTypeSelect.removeEventListener("focus", () => {});
      };
    }
  }, []);

  const [onlyNumbers, setOnlyNumbers] = useState(false);
  const [inputFocus, setInputFocus] = useState("TeamCode");

  useEffect(() => {
    const quantityInput = document.querySelector("#quantityInput");
    if (quantityInput) {
      quantityInput.addEventListener("focus", () => {
        setOnlyNumbers(true);
        setInputFocus("quantityInput");
        setNavmenuSelected("QUANTITY");
      });
      return () => {
        quantityInput?.removeEventListener("focus", () => {});
      };
    }
  }, []);
  useEffect(() => {
    const quantityInput = document.querySelector("#quantityInput");
    let ifWinInput = document.querySelector("#ifWinInput");
    if (!ifWinInput) return;
    ifWinInput.addEventListener("focus", () => {
      setOnlyNumbers(true);
      setInputFocus("ifWinInput");
      setNavmenuSelected("QUANTITY");
    });
    return () => {
      quantityInput?.removeEventListener("focus", () => {});
    };
  }, []);

  useEffect(() => {
    const quantityInput = document.querySelector("#quantityInput");
    let IfWinQuantity = document.querySelector("#IfWinQuantity");
    if (!IfWinQuantity) return;
    IfWinQuantity.addEventListener("focus", (e) => {
      setOnlyNumbers(true);
      setInputFocus("IfWinQuantity");
    });
    return () => {
      quantityInput?.removeEventListener("focus", () => {});
    };
  }, []);

  const [ifBetSwitch, setIfBetSwitch] = useState(false);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [IfWinQuantity, setIfWinQuantity] = useState(null);

  const handleEnterKey = useCallback(
    (e) => {
      if (e.key === "Enter" && bets.bets.length > 0) {
        let ifWinInput = document.querySelector("#ifWinInput");
        if (parseInt(ifWinInput.value) > 0) {
          let ifWinContainer = document.querySelector("#ifWinContainer");

          if (ifWinContainer.children.length === 1) {
            let newInput = document.createElement("input");
            newInput.type = "number";
            newInput.className = "form-control col";
            newInput.name = "IfWinQuantity";
            newInput.id = "IfWinQuantity";
            ifWinContainer.appendChild(newInput);
            newInput.focus();
            setIfWinQuantity(newInput);
            return;
          } else {
            document.querySelector("#IfWinQuantity").focus();
            return;
          }
        }
        let quantityInput = document.querySelector("#quantityInput");
        let ammountBet = bets.ammount == 0 ? quantityInput.value : bets.ammount;
        if (parseInt(ammountBet) > 0) {
          let betsTowin = bets.bets.map((bet) => {
            let price = bet.Price.toString().slice(-4);
            return {
              price: price,
            };
          });

          ifWinInput.disabled = true;
          quantityInput.disabled = true;

          let totalToWin = getToWin(betsTowin, parseInt(ammountBet));
          setTotalToWin(totalToWin);
        }
        setBets({
          ...bets,
          ammount: parseInt(ammountBet),
          readyToSubmit: true,
        });
        setBetToAdd({
          TeamCode: "",
          PlayType: "",
          Quantity: 0,
          IfWin: 0,
        });
      }
    },
    [bets]
  );
  const handleEnterKey2 = useCallback(
    (e) => {
      if (e.key === "Enter" && bets.bets.length > 0) {
        let ifWinInput = document.querySelector("#ifWinInput");
        if (parseInt(ifWinInput.value) > 0) {
          let ifWinContainer = document.querySelector("#ifWinContainer");
          let IfWinQuantity = document.querySelector("#IfWinQuantity2");
          if (IfWinQuantity) {
            IfWinQuantity.focus();
            return;
          }
          if (ifWinContainer.children.length === 1) {
            let newInput = document.createElement("input");
            newInput.type = "number";
            newInput.className = "form-control col";
            newInput.name = "IfWinQuantity";
            newInput.id = "IfWinQuantity";
            ifWinContainer.appendChild(newInput);
            newInput.focus();
            setIfWinQuantity(newInput);
            return;
          } else {
            document.querySelector("#IfWinQuantity").focus();
            return;
          }
        }
        let quantityInput = document.querySelector("#quantityInput");
        let ammountBet = bets.ammount == 0 ? quantityInput.value : bets.ammount;
        if (parseInt(ammountBet) > 0) {
          let betsTowin = bets.bets.map((bet) => {
            let price = bet.Price.toString().slice(-4);
            return {
              price: price,
            };
          });

          ifWinInput.disabled = true;
          quantityInput.disabled = true;

          let totalToWin = getToWin(betsTowin, parseInt(ammountBet));
          setTotalToWin(totalToWin);
        }
        setBets({
          ...bets,
          ammount: parseInt(ammountBet),
          readyToSubmit: true,
        });
        setBetToAdd({
          TeamCode: "",
          PlayType: "",
          Quantity: 0,
          IfWin: 0,
        });
      }
    },
    [bets]
  );

  const handleIfWinChange = (e) => {
    if (e.key === "Enter") {
      e.target.disabled = true;
      let ifWinInput = document.querySelector("#ifWinInput");
      // if (!parseInt(ifWinInput.value) > 0) return;
      let quantityInput = document.querySelector("#quantityInput");
      let ammountBet = bets.ammount == 0 ? quantityInput.value : bets.ammount;
      if (parseInt(ammountBet) > 0) {
        let betsTowin = bets.bets.map((bet) => {
          let price = bet.Price.toString().slice(-4);
          return {
            price: price,
          };
        });

        ifWinInput.disabled = true;
        quantityInput.disabled = true;

        let totalToWin = getToWin(
          betsTowin.slice(0, e.target.value),
          parseInt(ammountBet)
        );
        // console.log({ totalToWin });
        let totalToWiniF = getToWin(
          betsTowin.slice(e.target.value, betsTowin.length),
          parseInt(ifWinInput.value)
        );
        // console.log({ totalToWiniF });
        let toCollect = totalToWin + totalToWiniF;
        setTotalToWin(totalToWin);
        setTotalToWinIF(totalToWiniF);
      }

      setBets({
        ...bets,
        ammount: parseInt(ammountBet),
        betsIf: parseInt(e.target.value),
        IfWinQuantity: parseInt(ifWinInput.value),
        readyToSubmit: true,
      });

      setBetToAdd({
        TeamCode: "",
        PlayType: "",
        Quantity: 0,
        IfWin: 0,
      });
      e.target.value = "";
    }
  };
  const handleIfWinChange2 = (e) => {
    if (e.key === "Enter") {
      let ifWinInput = document.querySelector("#ifWinInput");
      let IfWinQuantity = document.querySelector("#IfWinQuantity2");
      IfWinQuantity.disabled = true;
      let quantityInput = document.querySelector("#quantityInput");
      let ammountBet = bets.ammount == 0 ? quantityInput.value : bets.ammount;
      if (parseInt(ammountBet) > 0) {
        let betsTowin = bets.bets.map((bet) => {
          let price = bet.Price.toString().slice(-4);
          return {
            price: price,
          };
        });

        ifWinInput.disabled = true;
        quantityInput.disabled = true;

        let totalToWin = getToWin(
          betsTowin.slice(0, IfWinQuantity.value),
          parseInt(ammountBet)
        );
        // console.log({ totalToWin });
        let totalToWiniF = getToWin(
          betsTowin.slice(IfWinQuantity.value, betsTowin.length),
          parseInt(ifWinInput.value)
        );
        // console.log({ totalToWiniF });
        let toCollect = totalToWin + totalToWiniF;
        setTotalToWin(totalToWin);
        setTotalToWinIF(totalToWiniF);
      }

      setBets({
        ...bets,
        ammount: parseInt(ammountBet),
        betsIf: parseInt(IfWinQuantity.value),
        IfWinQuantity: parseInt(ifWinInput.value),
        readyToSubmit: true,
      });

      setBetToAdd({
        TeamCode: "",
        PlayType: "",
        Quantity: 0,
        IfWin: 0,
      });
      document.querySelector("#IfWinQuantity2").value = "";
    }
  };

  const handleclickLeague = () => {
    setNavmenuSelected("TEAMCODE");
  };
  const [userTheme, setUserTheme] = useState(1);
  const handleChangeTheme = (e) => {
    setUserTheme(e.target.value);
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = async () => {
    setIsModalVisible(false);
    await redApi.get("/verify").then(async (res) => {
      if (res.data) {
        if (res.data._id) {
          await redApi
            .put(`/users/${res.data._id}`, { theme: userTheme })
            .then((res) => {
              Swal.fire({
                icon: "success",
                title: "Theme changed",
                showConfirmButton: false,
                timer: 1500,
              });
              window.location.reload();
            });
        }
      }
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {mobile ? (
        <div className="mobile_opener" onClick={handleOpenMenu}>
          {menuOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        </div>
      ) : null}
      {mobile ? (
        <section id="LayoutClientt3">
          <div className="header-mobile">
            <Modal
              title="Change Theme"
              open={isModalVisible}
              onCancel={handleCancel}
              width={"30%"}
              onOk={handleOk}
            >
              <div className="d-flex flex-column justify-content-center align-items-center py-2">
                <i className="fas fa-adjust px-1 h1"></i>
              </div>
              <div className="row d-flex flex-column justify-content-center align-items-center">
                <select
                  defaultValue={themeofuser || 0}
                  className="form-select col-6"
                  aria-label="Default select example"
                  onChange={handleChangeTheme}
                >
                  <option disabled value={0}>
                    Choose theme
                  </option>
                  <option
                    value="1"
                    selected={themeofuser === 1}
                    disabled={themeofuser === 1}
                  >
                    Legacy
                  </option>
                  <option
                    value="2"
                    selected={themeofuser === 1}
                    disabled={themeofuser === 2}
                  >
                    Red
                  </option>
                  <option
                    value="3"
                    selected={themeofuser === 1}
                    disabled={themeofuser === 3}
                  >
                    Azul
                  </option>
                  <option
                    value="4"
                    selected={themeofuser === 1}
                    disabled={themeofuser === 4}
                  >
                    Theme 4
                  </option>
                  <option
                    value="5"
                    selected={themeofuser === 1}
                    disabled={themeofuser === 4}
                  >
                    F83
                  </option>
                </select>
              </div>
            </Modal>
            <div className="header-mobile__top">
              <div>
                <span>
                  {navmenuSelected !== "JUGADAS" || navmenuSelected !== "ODDS"
                    ? "VENDER"
                    : navmenuSelected}
                </span>
              </div>
              <div className="header-mobile__top__icons">
                <div>
                  <CommentOutlined />
                </div>
                <div>
                  <button
                    onClick={() => showModal()}
                    style={{
                      background: "none",
                      border: "none",
                    }}
                  >
                    <BgColorsOutlined />
                  </button>
                </div>
                <div>
                  <LockOutlined />
                </div>
              </div>
            </div>
            <div className="header-mobile__bottom">
              <div
                onClick={() =>
                  handleNavmenuSelected({ target: { value: "VENDER" } })
                }
                className={navmenuSelected === "VENDER" ? "active" : ""}
              >
                VENDER
              </div>
              <div
                onClick={() =>
                  handleNavmenuSelected({ target: { value: "JUGADAS" } })
                }
                className={navmenuSelected === "JUGADAS" ? "active" : ""}
              >
                JUGADAS ({jugadas})
              </div>
              <div
                onClick={() =>
                  handleNavmenuSelected({ target: { value: "ODDS" } })
                }
                className={`odds ${navmenuSelected === "ODDS" ? "active" : ""}`}
              >
                ODDS
              </div>
            </div>
          </div>
          <>
            <div
              id="left-container"
              className="col-4 px-2 col-sm-12 col-md-12 col-lg-4"
              style={{
                display:
                  navmenuSelected === "VENDER" ||
                  navmenuSelected === "TEAMCODE" ||
                  navmenuSelected === "BETTYPE" ||
                  navmenuSelected === "QUANTITY"
                    ? "flex"
                    : "none",
              }}
            >
              <Legacy_leftMobile
                handleIfWinChange={handleIfWinChange}
                IfWinQuantity={IfWinQuantity}
                setIfWinQuantity={setIfWinQuantity}
                handleEnterKey={handleEnterKey}
                setBetToAdd={setBetToAdd}
                handleChangeBetToAdd={handleChangeBetToAdd}
                betToAdd={betToAdd}
                bets={bets}
                setBets={setBets}
                totalToWin={totalToWin}
                setTotalToWin={setTotalToWin}
                totalToWinIF={totalToWinIF}
                setTotalToWinIF={setTotalToWinIF}
                mobile={mobile}
                ifBetSwitch={ifBetSwitch}
                setIfBetSwitch={setIfBetSwitch}
                handleChangeCheckBoxs={handleChangeCheckBoxs}
                allowPrint={allowPrint}
              />
            </div>
            {/* <div className='leagues-container' style={{
                                display: navmenuSelected === 'TEAMCODE' && showKeyboard !== false ? 'flex' : 'none'
                            }}>
                                
                            </div> */}
            <div
              className="leagues-container"
              style={{
                display: navmenuSelected === "VENDER" ? "flex" : "none",
              }}
            >
              <Header
                isLoading={isLoading}
                mobile={mobile}
                handleclickLeague={handleclickLeague}
                navmenuSelected={navmenuSelected}
              />
            </div>
            <div
              className="leagues-container"
              style={{
                display: navmenuSelected === "TEAMCODE" ? "flex" : "none",
              }}
            >
              <TeamsMobile
                isLoading={isLoading}
                gamesForUser={gamesForUser}
                setBetToAdd={setBetToAdd}
                betToAdd={betToAdd}
                navmenuSelected={navmenuSelected}
              />
            </div>
            <div className="p-2">
              <Footer
                mobile={mobile}
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                setMobile={setMobile}
              />
            </div>
            <div
              id="right-containerm"
              style={{
                display: navmenuSelected !== "ODDS" ? "none" : "flex",
              }}
            >
              <Legacy_rightMobile
                gamesForUser={gamesForUser}
                isLoading={isLoading}
                setUserTheme={setUserTheme}
                setBetToAdd={setBetToAdd}
                betToAdd={betToAdd}
                setBets={setBets}
                bets={bets}
                handleBetAddToTable={handleBetAddToTable}
                mobile={mobile}
              />
            </div>
            <div
              id="keyboardmobile"
              className={`keyboard-container`}
              style={{
                display:
                  navmenuSelected === "BETTYPE" ||
                  navmenuSelected === "QUANTITY"
                    ? "flex"
                    : "none",
              }}
            >
              <MobileKeyboard
                setTotalToWinIF={setTotalToWinIF}
                setTotalToWin={setTotalToWin}
                handleIfWinChange={handleIfWinChange2}
                handleEnterKey={handleEnterKey2}
                ifBetSwitch={ifBetSwitch}
                inputFocus={inputFocus}
                setInputFocus={setInputFocus}
                setBetToAdd={setBetToAdd}
                handleChangeBetToAdd={handleChangeBetToAdd}
                betToAdd={betToAdd}
                bets={bets}
                setBets={setBets}
                handleBetAddToTable={handleBetAddToTable}
                onlyNumbers={onlyNumbers}
                setOnlyNumbers={setOnlyNumbers}
                handleBetPost={handleBetPost}
              />
            </div>
            <div
              className="col px-2"
              style={{
                display: navmenuSelected === "JUGADAS" ? "flex" : "none",
                width: "90vw",
              }}
            >
              {children}
            </div>
            <div
              className="footer-mobile"
              style={{
                display:
                  navmenuSelected === "VENDER" || navmenuSelected === "TEAMCODE"
                    ? "flex"
                    : "none",
              }}
            >
              <div className="footer-mobile__left">
                <select name="ticketselec" id="ticketselec">
                  <option value=""></option>
                </select>
              </div>
              <div className="footer-mobile__right">
                <div className="footer-mobile__right__icons">
                  <i
                    className="fa-solid fa-copy"
                    style={{
                      color: "#86898d",
                    }}
                  />
                </div>
                <div className="footer-mobile__right__icons">
                  <i
                    className="fa-solid fa-trash"
                    style={{
                      color: "#86898d",
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        </section>
      ) : (
        <section id="LayoutClientt3">
          <div className="p-2">
            <Header />
          </div>
          <div style={{ height: "100%" }} className="row g-0 px-xl-5 px-2">
            <div
              id="left-container"
              style={{ height: "100%" }}
              className="col-4 px-2 col-sm-12 col-md-12 col-lg-4"
            >
              <LeftContainer
                setBetToAdd={setBetToAdd}
                handleChangeBetToAdd={handleChangeBetToAdd}
                betToAdd={betToAdd}
                bets={bets}
                setBets={setBets}
                totalToWin={totalToWin}
                setTotalToWin={setTotalToWin}
                totalToWinIF={totalToWinIF}
                setTotalToWinIF={setTotalToWinIF}
              />
            </div>
            <div
              id="middle-container"
              className="col px-2 col-sm-12 col-md-12 col-lg-4"
            >
              {children}
            </div>

            <div
              id="right-container"
              className="col px-2 col-sm-12 col-md-12 col-lg-4"
            >
              <RightContainer
                gamesForUser={gamesForUser}
                isLoading={isLoading}
                setUserTheme={setUserTheme}
                setBetToAdd={setBetToAdd}
                betToAdd={betToAdd}
                setBets={setBets}
                bets={bets}
                handleBetAddToTable={handleBetAddToTable}
              />
            </div>
            <div className="p-2">
              <Footer
                mobile={mobile}
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                setMobile={setMobile}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Layout;
