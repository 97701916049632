import React, { useEffect, useState } from 'react'
import moment from 'moment'
import "./LayoutTableGames.scss"

export const TableGames = ({
    games,
    head
}) => {

    const [codeToView, setCodeToView] = useState("LG")

    const lastTimeApproved = games[0]?.lastTimeApproved?.split(' ')
    const lastHourApproved = lastTimeApproved ? lastTimeApproved[1] : ""
    const lastDateApproved = lastTimeApproved ? lastTimeApproved[0] : ""

    return (
        <div id='table-lines-responsive'>
            <div style={{ textAlign: 'center', justifyItems: 'center', border: 'solid 1px black', margin: '2px' }}>
                <p style={{ margin: 'auto' }}>Esta Liga fue aprovada a las: {lastHourApproved != "" ? lastHourApproved : "Liga Sin Aprovar"} hs  - Fecha: {lastDateApproved != "" ? lastDateApproved : "Liga Sin Aprovar"}</p>
            </div>
            <table className="w-100" id="table-theme-1">
                <thead >
                    <tr >
                        <th>Hora</th>
                        <th>Juego</th>
                        {
                            head?.map((item, index) => (
                                item.name !== "P MAS" && item.name !== "P MENOS" ? (
                                    <th key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>{item.name}</th>
                                ) : null
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        games?.map((game, indexGame) => (
                            <tr key={indexGame}>
                                <td> {moment(game.time).format("hh:mm A")}</td>
                                <td
                                    className={` ${game.status}`}
                                    style={{
                                        position: "sticky",
                                    }}
                                >
                                    <div className="d-flex flex-column teams">
                                        <div className="d-flex align-items-center gap-2">
                                            <img src={game.localTeam.image} alt="" />
                                            {" | "}
                                            <span>
                                                {game.manual ? `${game.localTeam.name}(M)` :
                                                    game.localTeam.name.length > 20
                                                        ? game.localTeam.name.slice(0, 20) + "..."
                                                        : game.localTeam.name
                                                }
                                            </span>
                                            {" - "}
                                            <span>
                                                {
                                                    codeToView === "RD" ? game.localTeam.codeRD : game.localTeam.code ? game.localTeam.code : game.localTeam.codeRD
                                                }
                                                {/* {game.localTeam.code} */}
                                                {/* {game.localTeam.codeRD} */}
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center gap-2">
                                            <img src={game.visitingTeam.image} alt="" />
                                            {" | "}
                                            <span>
                                                {
                                                    game.manual ? `${game.visitingTeam.name}(M)` :
                                                        game.visitingTeam.name.length > 20
                                                            ? game.visitingTeam.name.slice(0, 20) + "..."
                                                            : game.visitingTeam.name
                                                }
                                            </span>
                                            {" - "}
                                            <span>
                                                {
                                                    codeToView === "RD" ? game.visitingTeam.codeRD : game.visitingTeam.code ? game.visitingTeam.code : game.visitingTeam.codeRD
                                                }
                                                {/* {game.visitingTeam.code} */}
                                                {/* {game.visitingTeam.codeRD} */}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                {
                                    head?.map((item, index) => (
                                        item.name === "TOTAL" ? (
                                            <td key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>
                                                <div className="container_inputs">
                                                    <div
                                                    >
                                                        <span
                                                        >
                                                            {`${game.localLine[item.property]?.value} OVER ${game.localLine.posPlus?.value !== "-" ? game.localLine.posPlus?.value : ""}` || '-'}

                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            {`${game.visitingLine[item.property]?.value} UNDER ${game.visitingLine.posMinus?.value !== "-" ? game.visitingLine.posMinus?.value : ""}` || '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        ) : item.name && item.name !== "P MAS" && item.name !== "P MENOS" ? (

                                            <td key={index} className={`${item.property === "singleMinus" ? "border-end" : ""}`}>
                                                <div className="container_inputs">
                                                    <div>
                                                        <span>
                                                            {game.localLine[item.property]?.value || '-'}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            {game.visitingLine[item.property]?.value || '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        )
                                            : null
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}
