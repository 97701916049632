import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import "./LayoutTableGames.scss"
import Swal from 'sweetalert2'
import { playTypesHeadDictionary } from '../../../../utils/playTypes'


export const PlayTypesReverse = {
  // Money Line
  M: 'moneyLine',
  MH: 'h1',
  MT1: 'moneyLineT1',
  MT2: 'moneyLineT2',
  MT3: 'moneyLineT3',

  // Run line 
  R: 'price',
  H: 'h1Rl',
  Q1: 'RunLineQ1',
  Q2: 'RunLineQ2',
  Q3: 'RunLineQ3',
  Q4: 'RunLineQ4',
  T1: 'runLineT1',
  T2: 'runLineT2',
  T3: 'runLineT3',
  P: 'superRunLine',
  AR: 'altRunLine',
  HR2: 'h2Rl',

  // Over
  '+': 'posPlus',
  'H+': 'HOver',
  'Q1+': 'priceQ1Over',
  'Q2+': 'priceQ2Over',
  'Q3+': 'priceQ3Over',
  'Q4+': 'priceQ4Over',
  'T1+': 'priceT1Over',
  'T2+': 'priceT2Over',
  'T3+': 'priceT3Over',
  'H2+': 'H2Over',

  // under
  '-': 'posMinus',
  'H-': 'HUnder',
  'Q1-': 'priceQ1Over',
  'Q2-': 'priceQ2Over',
  'Q3-': 'priceQ3Over',
  'Q4-': 'priceQ4Over',
  'T1-': 'priceT1Under',
  'T2-': 'priceT2Under',
  'T3-': 'priceT3Under',
  'H2-': 'H2Under',

  // Solo +
  'S+': 'singlePlus',
  'H1S+': 'HSOver',
  'Q1S+': 'priceQ1SOver',
  'Q2S+': 'priceQ2SOver',
  'Q3S+': 'priceQ3SOver',
  'Q4S+': 'priceQ4SOver',
  'H2S+': 'H2SOver',

  // Solo -
  'S-': 'singleMinus',
  'H1S-': 'HSUnder',
  'Q1S-': 'priceQ1SUnder',
  'Q2S-': 'priceQ2SUnder',
  'Q3S-': 'priceQ3SUnder',
  'Q4S-': 'priceQ4SUnder',
  'H2S-': 'H2SUnder',

  // si / no
  Y: 'sia',
  N: 'noa',

  'K+': 'strikeoutsPlus',
  'K-': 'strikeoutsMinus',
  F: 'firstRun',
  C: 'Buying',
  V: 'Senta',
  'C+': 'BOver',
  'C-': 'BUnder',
  J1: 'Player1',
  J2: 'Player2',
  J3: 'Player3',
  J4: 'Player4',
  J5: 'Player5',
  G: 'GSML',
  'G+': 'GSOver',
  'G-': 'GSUnder',
  SE: 'Serie',
  T: 'third',
  'T+': 'TercioOver',
  'T-': 'TercioUnder',
  'V+': 'SOver',
  'V-': 'SUnder',
  E: 'draw',
  'H1+': 'OverH1',
  'H1-': 'UnderH1'

}
export const TableGamesT3 = ({
  games,
  head,
  setBetToAdd,
  betToAdd,
  handleBetAddToTable
}) => {

  const [codeToView, setCodeToView] = useState("LG")
  const [updatedbets, setUpdatedbets] = useState(null)

  const returnPlayType = (head, isLocal) => {
    let playType;
    if (head.property === "altGavela") {
      playType = playTypesHeadDictionary[head.property];
    } else {
      playType = playTypesHeadDictionary[head.name];
    }
    if (playType) {
      if (playType === "P") {
        return isLocal ? "+" : "-"
      }
      if (playType === "H2") {
        return isLocal ? "H+" : "H-"
      }
      return playType;
    };
    return name;
  }

  const handleaddBet = (line, game, head, teamCode) => {
    const isLocal = teamCode === game.localTeam?.code || teamCode === game.localTeam?.codeRD ? true : false
    const PlayType = returnPlayType(head, isLocal)
    setBetToAdd({
      ...betToAdd,
      TeamCode: teamCode,
      // PlayType: PlayType === "P" ? isLocal ? "+" : "-" : PlayType,
      PlayType,
    })
    setUpdatedbets(true)
    Swal.fire({
      title: 'Agregando apuesta',
      text: 'Espere un momento por favor',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      timer: 400,
    })
  }





  useEffect(() => {
    if (updatedbets) {
      handleBetAddToTable(
        {
          target: { name: "PlayType" },
          key: "Enter"
        }
      )
      setUpdatedbets(false)
    }
  }, [betToAdd, updatedbets])
  const lastTimeApproved = games[0].lastTimeApproved?.split(' ')
  const lastHourApproved = lastTimeApproved ? lastTimeApproved[1] : null
  const lastDateApproved = lastTimeApproved ? lastTimeApproved[0] : null
  return (
    <div id='table-lines-responsive'>
      <div style={{ textAlign: 'center', justifyItems: 'center' }}>
        <p style={{ margin: 'auto' }}>Esta Liga fue aprovada a las: {lastHourApproved != "" ? lastHourApproved : "Liga Sin Aprovar"} hs  - Fecha: {lastDateApproved != "" ? lastDateApproved : "Liga Sin Aprovar"}</p>
      </div>
      {
        games.map((game, indexGame) => (
          <div className='table-line' key={indexGame}>
            <div className='container-div time-game'>
              <div
                className='header-div'

              >
                {moment(game.time).format("hh:mm A")}
              </div>
              <div className="body-lines">
                <div className="team-container"
                  onClick={() => {
                    setBetToAdd({
                      ...betToAdd,
                      TeamCode: codeToView === "RD" ? game.localTeam?.codeRD : game.localTeam?.code ? game.localTeam?.code : game.localTeam?.codeRD,
                    })
                    document.getElementById("betTypeSelect").focus()
                  }}
                >
                  <img src={game.localTeam?.image} alt="" />
                  {" | "}
                  <span>
                    {
                      game.manual ? `${game.localTeam.name}(M)` :
                        game.localTeam?.name.length > 20
                          ? game.localTeam?.name.slice(0, 20) + "..."
                          : game.localTeam?.name
                    }
                  </span>
                  {" - "}
                  <span>
                    {
                      codeToView === "RD" ? game.localTeam?.codeRD : game.localTeam?.code ? game.localTeam?.code : game.localTeam?.codeRD
                    }
                    {/* {game.localTeam?.code} */}
                    {/* {game.localTeam?.codeRD} */}
                  </span>
                </div>
                <div className="team-container"
                  onClick={() => setBetToAdd({
                    ...betToAdd,
                    TeamCode: codeToView === "RD" ? game.visitingTeam?.codeRD : game.visitingTeam?.code ? game.visitingTeam?.code : game.visitingTeam?.codeRD,
                  })}
                >
                  <img src={game.visitingTeam?.image} alt="" />
                  {" | "}
                  <span>
                    {
                      game.manual ? `${game.visitingTeam.name}(M)` :
                        game.visitingTeam?.name.length > 20
                          ? game.visitingTeam?.name.slice(0, 20) + "..."
                          : game.visitingTeam?.name
                    }
                  </span>
                  {" - "}
                  <span>
                    {
                      codeToView === "RD" ? game.visitingTeam?.codeRD : game.visitingTeam?.code ? game.visitingTeam?.code : game.visitingTeam?.codeRD
                    }
                    {/* {game.visitingTeam?.code} */}
                    {/* {game.visitingTeam?.codeRD} */}
                  </span>
                </div>
              </div>
            </div>
            {
              head.map((head, indexHead) => (
                <Fragment key={indexHead}>
                  <RenderPlayType
                    head={head}
                    handleaddBet={handleaddBet}
                    game={game}
                  />
                </Fragment>
              ))
            }
          </div>
        ))
      }
    </div>
  )
}



const runlineHeads = ["TOTAL", "GAVELA R/L", "TOTAL H", "GAVELA RL/H"]
const headsWithNoRender = ["P MAS", "P MENOS", "PRECIO R/L", "SOLO", "SOLO H", "P MAS H", "P MENOS H", "PRECIO RL/H"];
const runLineHead = {
  "GAVELA R/L": "R.L",
  "GAVELA RL/H": "R.L/H",
}
const moneyLineHead = {
  "MONEY": "M.L",
}
const RenderPlayType = ({ head, handleaddBet, game }) => {
  if (headsWithNoRender.includes(head.name)) return null;


  if (runlineHeads.includes(head.name)) {
    let localTotalValue = "N/A";
    let visitinglocalTotalValue = "N/A";
    switch (head.name) {
      case "TOTAL":
        localTotalValue = `${game.localLine?.[head.property]?.value} OVER ${game.localLine?.posPlus?.value !== "-" ? game.localLine?.posPlus?.value : ""}` || '-';
        visitinglocalTotalValue = `${game.visitingLine?.[head.property]?.value} UNDER ${game.visitingLine?.posMinus?.value !== "-" ? game.visitingLine?.posMinus?.value : ""}` || '-';
        break;
      case "TOTAL H":
        localTotalValue = `${game.localLine?.[head.property]?.value} OVER ${game.localLine?.HOver?.value !== "-" ? game.localLine?.HOver?.value : ""}` || '-';
        visitinglocalTotalValue = `${game.visitingLine?.[head.property]?.value} UNDER ${game.visitingLine?.HUnder?.value !== "-" ? game.visitingLine?.HUnder?.value : ""}` || '-';
        break;
      case "GAVELA R/L":
        // console.log({name: head.name, property: head.property})
        // if (head.property === "gavela") {
        localTotalValue = `${game.localLine?.[head.property]?.value || "N/A"} ${game.localLine?.price.value || "N/A"}`;
        visitinglocalTotalValue = `${game.visitingLine?.[head.property]?.value || "N/A"} ${game.visitingLine?.price.value || "N/A"}`;
        // } else if (head.property === "altGavela") {
        // localTotalValue = `${game.localLine?.[head.property]?.value ?? "N/A"} ${game.localLine?.price.value ?? "N/A"}`;
        // visitinglocalTotalValue = `${game.visitingLine?.[head.property]?.value ?? "N/A"} ${game.visitingLine?.price.value ?? "N/A"}`; 
        // }
        break;
      case "GAVELA RL/H":
        localTotalValue = `${game.localLine?.[head.property]?.value || "N/A"} ${game.localLine?.h1Rl.value || "N/A"}`;
        visitinglocalTotalValue = `${game.visitingLine?.[head.property]?.value || "N/A"} ${game.visitingLine?.h1Rl.value || "N/A"}`;
        break;
      // default: 
      //     localTotalValue = "N/A"; visitinglocalTotalValue = "N/A";
      //     break;
    }
    return (
      <div className='container-div'>
        <div className='header-div'>
          {runLineHead[head.name] ?? head.name}
          {/* {head.name === "GAVELA R/L" ? "R.L" : head.name === "GAVELA RL/H" ? "R.L/H" : head.name} */}
        </div>
        <div className='body-lines'>
          <div className="line-container"
            onClick={() => handleaddBet(game.localLine, game, head, game.localTeam?.code || game.localTeam?.codeRD)}
          >
            {localTotalValue}
          </div>
          <div className="line-container"
            onClick={() => handleaddBet(game.visitingLine, game, head, game.visitingTeam?.code || game.visitingTeam?.codeRD)}
          >
            {visitinglocalTotalValue}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className='container-div' >
        <div className='header-div'>
          {moneyLineHead[head.name] ?? head.name}
        </div>

        <div className='body-lines'>
          <div className="line-container"
            onClick={() => handleaddBet(game.localLine, game, head, game.localTeam?.code || game.localTeam?.codeRD)}
          >
            <span>
              {game.localLine?.[head.property]?.value || "N/A"}
            </span>
          </div>
          <div className="line-container"
            onClick={() => handleaddBet(game.visitingLine, game, head, game.visitingTeam?.code || game.visitingTeam?.codeRD)}
          >
            <span>
              {game.visitingLine?.[head.property]?.value || "N/A"}
            </span>
          </div>
        </div>
      </div>
    )
  }
}