import React, { useState, useEffect, useCallback, useContext } from "react";
import moment from "moment-timezone";
import { redApi } from "../../../../config/Axios";
import Cookies from "universal-cookie";
import {
  MobileOutlined,
  PrinterOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import { GlobalContext } from "../../../../globalContext";
const cookie = new Cookies();

export default function Legacy_leftMobile({
  mobile,
  setBetToAdd,
  handleChangeBetToAdd,
  betToAdd,
  bets,
  setBets,
  totalToWin,
  setTotalToWin,
  totalToWinIF,
  setTotalToWinIF,
  setIfBetSwitch,
  ifBetSwitch,
  handleChangeCheckBoxs,
  allowPrint,
  handleEnterKey,
  IfWinQuantity,
  setIfWinQuantity,
  handleIfWinChange,
  getToWin,
}) {
  const betType = [
    "M",
    "MH",
    "MT 1",
    "MT 2",
    "-MT 3",
    "R",
    "H",
    "Q1",
    "Q2",
    "Q3",
    "Q4",
    "T1",
    "T2",
    "T3",
    "HR2",
    "+",
    "H+",
    "Q1 +",
    "Q2+",
    "Q3+",
    "Q4+",
    "T1+",
    "T2+",
    "T3+",
    "H2+",
    "-",
    "H –",
    "Q1 –",
    "Q2 –",
    "Q3 –",
    "Q4 –",
    "T1 –",
    "T2 –",
    "T3 –",
    "H2 –",
    "S+",
    "H1S +",
    "Q1S +",
    "Q2S +",
    "Q3S +",
    "Q4S +",
    "H2S +",
    "S-",
    "H1 S-",
    "Q1 S-",
    "Q2 S-",
    "Q3 S-",
    "Q4 S-",
    "H2S –",
    "P",
    "Y",
    "N",
    "K+",
    "K –",
    "F",
    "C",
    "V",
    "C+",
    "C-",
    "J1",
    "J2",
    "J3",
    "J4",
    "J5",
    "G",
    "G+",
    "G –",
    "SE",
    "AR",
    "T +",
    "T-",
    "V+",
    "V-",
    "E",
    "HR1",
    "HR1 +",
    "H1 –",
  ];
  const [todayAndTime, setTodayAndTime] = useState(
    moment.tz("America/New_York").format("YYYY-MM-DD hh:mm A")
  );
  const { sameTeamPlay, handleSameTeamPlay } = useContext(GlobalContext);

  useEffect(() => {
    if (sameTeamPlay) {
      setBetToAdd({ ...betToAdd, PlayType: "" });
      document.querySelector("#TeamCode").focus();
    }
  }, [sameTeamPlay]);
  useEffect(() => {
    const interval = setInterval(() => {
      let mommentResult = moment
        .tz("America/New_York")
        .format("YYYY-MM-DD hh:mm A");
      setTodayAndTime(mommentResult);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // let ifWinContainer = document.querySelector('#ifWinContainer');
  // let newInput = document.createElement('input');
  // newInput.type = 'number';
  // newInput.className = 'form-control col';
  // newInput.name = 'IfWinQuantity';
  // ifWinContainer.appendChild(newInput);
  // newInput.focus();

  useEffect(() => {
    if (bets.bets?.length > 0) {
      // setIfWinQuantity(null);
      let IfWinQuantity = document.querySelector("#IfWinQuantity");
      if (IfWinQuantity) {
        IfWinQuantity.remove();
      }
    }
  }, [bets]);

  const handleIfWinblur = (e) => {
    // when blur and bets length is bigger than 0 set in bets
    if (e.target.value && bets.bets.length > 0) {
      setBets({
        ...bets,
        readyToSubmit: true,
      });
    }
  };
  useEffect(() => {
    let IfWinQuantity = document.querySelector("#IfWinQuantity");
    if (IfWinQuantity) {
      IfWinQuantity.addEventListener("keydown", handleIfWinChange);
      // un focus event listener
      IfWinQuantity.addEventListener("blur", handleIfWinblur);
      return () => {
        IfWinQuantity.removeEventListener("keydown", handleIfWinChange);
        IfWinQuantity.removeEventListener("blur", handleIfWinChange);
      };
    }
  }, [IfWinQuantity]);

  const handleUnFocusTeamCode = (e) => {
    // if(
    // reset all inputs
    let ifWinInput = document.querySelector("#ifWinInput");
    let quantityInput = document.querySelector("#quantityInput");
    let IfWinQuantity = document.querySelector("#IfWinQuantity");
    if (IfWinQuantity) {
      IfWinQuantity.remove();
    }
    if (ifWinInput) {
      ifWinInput.disabled = false;
      ifWinInput.value = "";
    }
    if (quantityInput) {
      quantityInput.disabled = false;
      quantityInput.value = "";
    }
    setTotalToWin(0);
    setTotalToWinIF(0);
    // set bets to empty only save the bets.bets array
    let betsToSave = bets.bets;
    setBets({
      ...bets,
      bets: betsToSave,
      betsIf: 0,
      ammount: 0,
      IfWinQuantity: 0,
      readyToSubmit: false,
    });
  };
  useEffect(() => {
    if (bets.bets?.length > 0) {
      let teamCode = document.querySelector("#TeamCode");
      teamCode.addEventListener("focus", handleUnFocusTeamCode);

      return () => {
        teamCode.removeEventListener("focus", handleUnFocusTeamCode);
      };
    }
  }, [bets]);

  useEffect(() => {
    if (mobile) return;
    let ifWinInput = document.querySelector("#ifWinInput");
    ifWinInput.addEventListener("keydown", handleEnterKey);
    return () => {
      ifWinInput.removeEventListener("keydown", handleEnterKey);
    };
  }, [bets]);

  useEffect(() => {
    let quantityInput = document.querySelector("#quantityInput");
    quantityInput.addEventListener("keydown", handleQuantityEnterKey);

    return () => {
      quantityInput.removeEventListener("keydown", handleQuantityEnterKey);
    };
  }, []);

  const handleFocusTeamCode = (e) => {
    if (
      e.key === "Enter" &&
      bets.bets.length === 0 &&
      e.target.id !== "TeamCode"
    ) {
      document.querySelector("#TeamCode").focus();
      document.querySelector("#TeamCode").select();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleFocusTeamCode);

    return () => {
      document.removeEventListener("keydown", handleFocusTeamCode);
    };
  }, [bets]);

  const handleQuantityEnterKey = (e) => {
    if (e.key === "Enter") {
      // setBets({
      //     ...bets,
      //     ammount: parseInt(e.target.value)
      // })
      document.querySelector("#ifWinInput").focus();
      document.querySelector("#ifWinInput").select();
    }
  };

  const handleSpaceKey = (e) => {
    if (e.key === " ") {
      e.preventDefault();
      if (bets.bets.length > 0) {
        document.querySelector("#quantityInput").focus();
        document.querySelector("#quantityInput").select();
      } else {
        document.querySelector("#TeamCode").focus();
        document.querySelector("#TeamCode").select();
      }
    }
  };

  const handleEnterCodeTeam = async (e) => {
    // console.log("EEEEEEEEEE", e)
    if (e.target.value == "" && e.key === "Enter") {
      e.preventDefault();
      // console.log("PASANDOOO")
      document.querySelector("#quantityInput").focus();
      document.querySelector("#quantityInput").select();
    }
  };

  const handleLogin = async (e) => {
    await redApi.get(`/users/login/as/${e.target.value}`).then((res) => {
      if (!cookie.get("loginAs")) {
        cookie.set("loginAs", res.data.token, { path: "/" });
        window.location.href = "/client";
        return;
      }
      cookie.remove("loginAs", { path: "/" });
      cookie.set("loginAs", res.data.token, { path: "/" });
      // window.location.href = "/client";
    });
  };

  useEffect(() => {
    document.addEventListener("keydown", handleSpaceKey);

    return () => {
      document.removeEventListener("keydown", handleSpaceKey);
    };
  }, [bets]);

  const getCurrentUser = async () => {
    await redApi
      .get(`/verify`)
      .then((res) => {
        setTerminalLogged(res.data);
      })
      .catch((err) => console.log(err));
  };
  const [terminalLogged, setTerminalLogged] = useState({});
  const [terminalsToLogin, setTerminalsToLogin] = useState([]);
  const getTerminals = async () => {
    if (terminalLogged && terminalLogged.userLevel < 3) {
      await redApi
        .get("/users/getterminalsofconsortium")
        .then((res) => {
          setTerminalsToLogin(res.data);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getCurrentUser();
  }, []);
  useEffect(() => {
    getTerminals().catch((err) => console.log(err));
  }, [terminalLogged]);
  return (
    // <div className="h-100 d-flex flex-column justify-content-start align-items-center">
    <div className="bg-card">
      <div className="ticket-top position-relative">
        {mobile ? (
          <>
            <div className="d-flex flex-row justify-content-between align-items-center mb-2">
              <div className="col d-flex flex-row justify-content-center align-items-center username">
                {terminalLogged?.username}
              </div>
              <div className="col d-flex flex-row justify-content-center align-items-center">
                <input
                  type="date"
                  disabled
                  value={new Date().toISOString().slice(0, 10)}
                  className="text-center form-control datetime"
                />
              </div>
            </div>
            <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2 inputs">
              <div className="col d-flex flex-column justify-content-center align-items-center">
                <input
                  type="number"
                  className="form-control"
                  name="TeamCode"
                  placeholder="Equipo"
                  id="TeamCode"
                  value={betToAdd.TeamCode}
                  onChange={handleChangeBetToAdd}
                  onKeyDown={handleEnterCodeTeam}
                  inputMode="none"
                />
              </div>
              <div className="col d-flex flex-column justify-content-center align-items-center">
                <input
                  type="text"
                  className="form-control"
                  name="PlayType"
                  placeholder="Tipo"
                  value={betToAdd.PlayType}
                  onChange={handleChangeBetToAdd}
                  id="betTypeSelect"
                  inputMode="none"
                />
              </div>
              <div className="col d-flex flex-column justify-content-center align-items-center">
                <input
                  type="number"
                  className="form-control"
                  name="Quantity"
                  placeholder="Monto"
                  value={betToAdd.Quantity}
                  onChange={handleChangeBetToAdd}
                  id="quantityInput"
                  inputMode="none"
                />
              </div>
            </div>
            <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2 mt-1 mb-4">
              <div className="col d-flex flex-column justify-content-center align-items-center">
                <div
                  id="ifWinContainer"
                  className="col d-flex flex-row justify-content-center align-items-center gap-2"
                >
                  <input
                    type="number"
                    className="form-control col"
                    name="IfWin"
                    value={betToAdd.IfWin}
                    onChange={handleChangeBetToAdd}
                    id="ifWinInput"
                    placeholder="IF"
                    style={{
                      display: ifBetSwitch ? "flex" : "none",
                    }}
                    inputMode="none"
                  />
                  <input
                    type="number"
                    className="form-control col"
                    name="IfWinQuantity2"
                    value={bets.IfWinQuantity}
                    onChange={handleChangeBetToAdd}
                    id="IfWinQuantity2"
                    placeholder="IF Quantity"
                    style={{
                      display: ifBetSwitch ? "flex" : "none",
                    }}
                    inputMode="none"
                  />
                  {/* <input type="number" className="form-control col" name='IfWin2' value={betToAdd.IfWin} onChange={handleChangeBetToAdd} id="IfWin2" placeholder='Quantity IF ' style={{
                                        display: ifBetSwitch ? 'flex' : 'none'
                                    }} /> */}
                </div>
              </div>
              {/* {
                                ifBetSwitch ?
                                    <>
                                    </>
                                    : null
                            } */}
            </div>
          </>
        ) : (
          <>
            <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
              <div className="col d-flex flex-row justify-content-center align-items-center">
                <input
                  type="date"
                  disabled
                  value={new Date().toISOString().slice(0, 10)}
                  className="text-center form-control"
                />
              </div>
              <div className="col d-flex flex-row justify-content-center align-items-center">
                <select
                  disabled={terminalLogged?.userLevel < 3 ? false : true}
                  className="form-select"
                  onChange={handleLogin}
                >
                  {terminalLogged?.userLevel < 3 ? (
                    terminalsToLogin.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.username}
                      </option>
                    ))
                  ) : (
                    <option value={terminalLogged?._id}>
                      {terminalLogged?.username}
                    </option>
                  )}
                </select>
              </div>
            </div>
            <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
              <div className="col d-flex flex-column justify-content-center align-items-center">
                <p className="text-center mb-1">Code</p>
                <input
                  type="number"
                  className="form-control"
                  name="TeamCode"
                  id="TeamCode"
                  value={betToAdd.TeamCode}
                  onChange={handleChangeBetToAdd}
                  onKeyDown={handleEnterCodeTeam}
                />
              </div>
              <div className="col d-flex flex-column justify-content-center align-items-center">
                <p className="text-center mb-1">Play type</p>
                <input
                  type="text"
                  className="form-control"
                  name="PlayType"
                  value={betToAdd.PlayType}
                  onChange={handleChangeBetToAdd}
                  id="betTypeSelect"
                />
                {/* <select className="form-select betType" name='PlayType' value={betToAdd.PlayType} onChange={handleChangeBetToAdd} id="betTypeSelect">
                                <option value="" disabled></option>
                                {
                                    betType.map((item, index) => {
                                        return (
                                            <option key={index} value={item}>{item}</option>
                                        )
                                    })
                                }
                            </select> */}
              </div>
            </div>
            <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
              <div className="col d-flex flex-column justify-content-center align-items-center">
                <p className="text-center mb-1">Quantity</p>
                <input
                  type="number"
                  className="form-control"
                  name="Quantity"
                  value={betToAdd.Quantity}
                  onChange={handleChangeBetToAdd}
                  id="quantityInput"
                />
              </div>
              <div className="col d-flex flex-column justify-content-center align-items-center">
                <p className="text-center mb-1">IF</p>
                <div
                  id="ifWinContainer"
                  className="col d-flex flex-row justify-content-center align-items-center gap-2"
                >
                  <input
                    type="number"
                    className="form-control col"
                    name="IfWin"
                    value={betToAdd.IfWin}
                    onChange={handleChangeBetToAdd}
                    id="ifWinInput"
                  />
                </div>
              </div>
            </div>
            <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
              <div className="col d-flex flex-row justify-content-center align-items-center">
                <span className="text-center mb-1">
                  Server Date/Time:
                  {"  " + todayAndTime}
                </span>
              </div>
            </div>
            <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
              <div className="col d-flex flex-row justify-content-center align-items-center">
                <p className="text-center mb-1">Closing Time</p>
              </div>
              <div className="col d-flex flex-row justify-content-start align-items-center">
                <input
                  type="time"
                  className="form-control"
                  value={new Date().toLocaleTimeString()}
                  disabled
                />
              </div>
            </div>
            <div className="border-bottom-ticket"></div>
          </>
        )}
      </div>
      {mobile ? (
        <div className="ticket-bottom">
          <div className="w-100 d-flex flex-row justify-content-between align-items-center gap-2 px-2">
            <div>
              <button>teaser</button>
              <button>teaser if</button>
            </div>
            <div className="ifcheckbox">
              <input
                id="toggle-on"
                className="toggle toggle-left"
                name="toggle"
                type="radio"
                onChange={(e) => {
                  setIfBetSwitch(false);
                }}
                value={ifBetSwitch === false}
                checked={ifBetSwitch === false}
              />
              <label for="toggle-on" className="btn">
                off
              </label>
              <input
                id="toggle-off"
                className="toggle toggle-right"
                name="toggle"
                type="radio"
                onChange={(e) => {
                  setIfBetSwitch(true);
                }}
                value={ifBetSwitch === true}
                checked={ifBetSwitch === true}
              />
              <label for="toggle-off" className="btn">
                on
              </label>
            </div>
          </div>
          <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2 current-bet">
            <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
              <div className="w-100 d-flex flex-row justify-content-center align-items-center ">
                <div className="col d-flex flex-row justify-content-center align-items-center">
                  <p className="text-center mb-1">Pago:</p>
                </div>
                <div className="col d-flex flex-row justify-content-start align-items-center">
                  <span className="text-left">
                    ${" "}
                    {totalToWin > 0
                      ? totalToWin + totalToWinIF + bets.ammount
                      : 0.0}
                  </span>
                </div>
              </div>
              <div className="w-100 d-flex flex-row justify-content-center align-items-center ">
                <div className="col d-flex flex-row justify-content-center align-items-center">
                  <p className="text-center mb-1">IF:</p>
                </div>
                <div className="col d-flex flex-row justify-content-start align-items-center">
                  <span className="text-left">
                    $ {totalToWinIF > 0 ? totalToWinIF : 0.0}
                  </span>
                </div>
              </div>
              {/* <div className="w-100 d-flex flex-row justify-content-center align-items-center">
                                    <div className="col d-flex flex-row justify-content-center align-items-center">
                                        <p className="text-center mb-1">To win:</p>
                                    </div>
                                    <div className="col d-flex flex-row justify-content-start align-items-center">
                                        <span className="text-left">${totalToWin > 0 ? totalToWin : 0.00}</span>
                                    </div>
                                </div> */}
            </div>
            <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
              <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                <div className="col d-flex flex-row justify-content-center align-items-center">
                  <input
                    type="checkbox"
                    name="print"
                    onChange={handleChangeCheckBoxs}
                    checked={allowPrint.print}
                  />
                </div>
                <div className="col d-flex flex-row justify-content-start align-items-center">
                  <PrinterOutlined />
                </div>
              </div>
              <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
                <div className="col d-flex flex-row justify-content-center align-items-center">
                  <input
                    type="checkbox"
                    name="wsp"
                    onChange={handleChangeCheckBoxs}
                  />
                </div>
                <div className="col d-flex flex-row justify-content-start align-items-center">
                  <WhatsAppOutlined checked={allowPrint.wsp} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="ticket-bottom">
          <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
            <div className="col d-flex flex-row justify-content-center align-items-center">
              <p className="text-center mb-1">Ticket Total</p>
            </div>
            <div className="col d-flex flex-row justify-content-start align-items-center">
              <span className="text-left">
                $ {bets.ammount > 0 ? bets.ammount : ""}
              </span>
            </div>
          </div>
          <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
            <div className="col d-flex flex-row justify-content-center align-items-center">
              <p className="text-center mb-1">Ticket Total</p>
            </div>
            <div className="col d-flex flex-row justify-content-start align-items-center">
              <span className="text-left">
                $ {bets.ammount > 0 ? bets.ammount : ""}
              </span>
            </div>
          </div>
          <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
            <div className="col d-flex flex-row justify-content-center align-items-center">
              <p className="text-center mb-1">To win</p>
            </div>
            <div className="col d-flex flex-row justify-content-start align-items-center">
              <span className="text-left">
                ${totalToWin > 0 ? totalToWin : 0.0}
              </span>
            </div>
          </div>
          <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
            <div className="col d-flex flex-row justify-content-center align-items-center">
              <p className="text-center mb-1">To win IF</p>
            </div>
            <div className="col d-flex flex-row justify-content-start align-items-center">
              <span className="text-left">
                $ {totalToWinIF > 0 ? totalToWinIF : 0.0}
              </span>
            </div>
          </div>
          <div className="w-100 d-flex flex-row justify-content-center align-items-center gap-2 px-2">
            <div className="col d-flex flex-row justify-content-center align-items-center">
              <p className="text-center mb-1">To Collect</p>
            </div>
            <div className="col d-flex flex-row justify-content-start align-items-center">
              <span className="text-left">
                ${" "}
                {totalToWin > 0
                  ? totalToWin + totalToWinIF + bets.ammount
                  : 0.0}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
    // </div>
  );
}
