// CounterContext.js
import React, { createContext, useState } from "react";

// Crear el contexto
export const GlobalContext = createContext();

// Crear el proveedor del contexto
export const GlobalContextProvider = ({ children }) => {
  const [sameTeamPlay, setSameTeamPlay] = useState(false);

  // Función para incrementar el contador
  const handleSameTeamPlay = (value) => setSameTeamPlay(value);

  return (
    <GlobalContext.Provider value={{ sameTeamPlay, handleSameTeamPlay }}>
      {children}
    </GlobalContext.Provider>
  );
};
