import React, { useContext } from "react";
import Footer from "../../navigation/webuser/Webuser_footer";
import Header from "../../navigation/webuser/Webuser_header";
import LeftContainer from "../../navigation/webuser/Webuser_left";
import RightContainer from "../../navigation/webuser/Webuser_right";
import "./WebuserLayout.scss";
import { GlobalContext } from "../../../../globalContext";

const WebuserLayout = ({
  children,
  bets,
  setBets,
  betToAdd,
  setBetToAdd,
  gamesForUser,
  isLoading,
  setUserTheme,
  totalToWin,
  setTotalToWin,
}) => {
  const { sameTeamPlay, handleSameTeamPlay } = useContext(GlobalContext);

  const handleChangeBetToAdd = (e) => {
    let { name, value } = e.target;

    // Si el nombre es "TeamCode" y la longitud del valor es mayor a 4, lo corta a los primeros 4 caracteres
    if (name === "TeamCode" && value.length > 4) {
      value = value.slice(0, 4); // Corta el valor a 4 caracteres
    }

    if (name === "betTypeSelect") {
      handleSameTeamPlay(false);
    }

    if (name === "TeamCode" && betToAdd.TeamCode !== value) {
        handleSameTeamPlay(false);
      }

    // Actualiza el estado con el valor ajustado
    setBetToAdd({
      ...betToAdd,
      [name]: value.toUpperCase(),
    });

    // Enfocar según las condiciones
    if (sameTeamPlay) {
      document.querySelector("#TeamCode").focus();
    } else if (name === "TeamCode" && value.length === 4) {
      console.log("3");
      document.querySelector("#betTypeSelect").focus();
    }
  };

  return (
    <>
      <section id="LayoutClientWeb" className="main-sports-wrapper">
        <div className="p-2">
          <Header />
        </div>
        <div style={{ height: "100%" }} className="row g-0 px-xl-5 px-2 gap-2">
          <div
            id="left-container"
            style={{ height: "100%" }}
            className="col-12 h-100"
          >
            <LeftContainer
              setBetToAdd={setBetToAdd}
              handleChangeBetToAdd={handleChangeBetToAdd}
              betToAdd={betToAdd}
              bets={bets}
              setBets={setBets}
              totalToWin={totalToWin}
              setTotalToWin={setTotalToWin}
            />
          </div>

          <div id="middle-container" className="col-12">
            {children}
          </div>
          <div id="right-container" className="col-12">
            <RightContainer
              gamesForUser={gamesForUser}
              isLoading={isLoading}
              setUserTheme={setUserTheme}
            />
          </div>
        </div>
        <div className="p-2">
          <Footer />
        </div>
      </section>
    </>
  );
};

export default WebuserLayout;
