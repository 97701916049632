import React, { useContext } from "react";
import Footer from "../navigation/Legacy_footer";
import Header from "../navigation/Legacy_header";
import LeftContainer from "../navigation/Legacy_left";
import RightContainer from "../navigation/Legacy_right";
import "./Legacy_layout.scss";
import { GlobalContext } from "../../../globalContext";

const Layout = ({
  children,
  bets,
  setBets,
  betToAdd,
  setBetToAdd,
  gamesForUser,
  isLoading,
  setUserTheme,
  totalToWin,
  setTotalToWin,
  totalToWinIF,
  setTotalToWinIF,
  handleBetPost,
}) => {
  const { sameTeamPlay, handleSameTeamPlay } = useContext(GlobalContext);

  const handleChangeBetToAdd = (e) => {
    let { name, value } = e.target;

    // Si el nombre es "TeamCode" y la longitud del valor es mayor a 4, lo corta a los primeros 4 caracteres
    if (name === "TeamCode" && value.length > 4) {
      value = value.slice(0, 4); // Corta el valor a 4 caracteres
    }

    if (name === "betTypeSelect") {
      handleSameTeamPlay(false);
    }

    if (name === "TeamCode" && betToAdd.TeamCode !== value) {
        handleSameTeamPlay(false);
      }

    // Actualiza el estado con el valor ajustado
    setBetToAdd({
      ...betToAdd,
      [name]: value.toUpperCase(),
    });

    // Enfocar según las condiciones
    if (sameTeamPlay) {
      document.querySelector("#TeamCode").focus();
    } else if (name === "TeamCode" && value.length === 4) {
      console.log("3");
      document.querySelector("#betTypeSelect").focus();
    }
  };

  return (
    <>
      <section id="LayoutClient" className="main-sports-wrapper">
        <div className="p-2">
          <Header />
        </div>
        <div
          style={{ height: "100%" }}
          className="row g-0 px-xl-5 px-2"
          id="main-container"
        >
          <div
            id="left-container"
            style={{ height: "100%" }}
            className="col-4 px-2 col-sm-12 col-md-12 col-lg-4"
          >
            <LeftContainer
              setBetToAdd={setBetToAdd}
              handleChangeBetToAdd={handleChangeBetToAdd}
              betToAdd={betToAdd}
              bets={bets}
              setBets={setBets}
              totalToWin={totalToWin}
              setTotalToWin={setTotalToWin}
              totalToWinIF={totalToWinIF}
              setTotalToWinIF={setTotalToWinIF}
              handleBetPost={handleBetPost}
            />
          </div>
          <div
            id="middle-container"
            className="col px-2 col-sm-12 col-md-12 col-lg-4"
          >
            {children}
          </div>
          <div
            id="right-container"
            className="col px-2 col-sm-12 col-md-12 col-lg-4"
          >
            <RightContainer
              gamesForUser={gamesForUser}
              isLoading={isLoading}
              setUserTheme={setUserTheme}
            />
          </div>
        </div>
        <div className="p-2">
          <Footer handleBetPost={handleBetPost} />
        </div>
      </section>
    </>
  );
};

export default Layout;
